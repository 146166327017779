import JSCookie from 'js-cookie';

const UH_KEY = 'userHash';
const REFERRALS_COOKIE = 'referralsToken';
const REFERRALS_COOKIE_DOMAIN = '.wwrk.co';
const REFERRALS_COOKIE_EXPIRES = 14;

export const UserLocalStorage = {
  clear(): void {
    window.localStorage.removeItem(UH_KEY);
  },
  get(): object {
    const itemString = window.localStorage.getItem(UH_KEY);
    return itemString ? JSON.parse(itemString) : null;
  },
  set(value: object): void {
    window.localStorage.setItem(UH_KEY, JSON.stringify(value));
  },
  update(value: object): void {
    const item = this.get();
    window.localStorage.setItem(UH_KEY, JSON.stringify({ ...value, ...item }));
  },
};

/* eslint-disable @typescript-eslint/camelcase */
export const UserCookies = {
  clear(): void {
    JSCookie.remove(REFERRALS_COOKIE, { domain: REFERRALS_COOKIE_DOMAIN });
  },
  get() {
    return JSCookie.get(REFERRALS_COOKIE);
  },
  getUTMs() {
    const { u_campaign, u_content, u_from, u_lp, u_medium, u_source, u_term, u_time } = JSCookie.getJSON();
    return {
      u_campaign,
      u_content,
      u_from,
      u_lp,
      u_medium,
      u_source,
      u_term,
      u_time,
    };
  },
  set(token: string): void {
    JSCookie.set(REFERRALS_COOKIE, token, {
      domain: REFERRALS_COOKIE_DOMAIN,
      expires: REFERRALS_COOKIE_EXPIRES,
    });
  },
};
/* eslint-enable @typescript-eslint/camelcase */
